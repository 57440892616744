
<template>
  <div class="wrap-content">
    <div class="header" :class="{ fixed: isFiexd == true }">
      <div class="return">
        <div class="return-icon" @click="modal1 = true">
          <Icon type="ios-arrow-back" />
        </div>
        <span>数据目录填报</span>
      </div>
      <ul>
        <li :class="navName == item.value ? 'active' : ''" v-for="(item, i) in nav" :key="i" :id="'info' + i">
          <a href="javascript:void(0)" @click="goAnchor(item.value)">{{
            item.title
          }}</a>
        </li>
      </ul>
      <div class="button">
        <div class="btn btn-simple" @click="modal1 = true">取消</div>
        <!-- <div class="btn btn-simple" @click="Staging">暂存草稿</div> -->
        <div class="btn btn-default" @click="submitData" v-if="!isEdit">
          保存
        </div>
        <div class="btn btn-default" @click="updateData" v-if="isEdit">
          保存
        </div>
        <CommonHeader />
      </div>
    </div>
    <div class="main">
      <div class="main-content">
        <div class="info" id="basic">
          <div class="title margin-20">
            <img src="../../assets/img/基本信息-已填写.png" alt="" />
            <span>基本信息</span>
          </div>
          <div class="form">
            <Form ref="formBasicValidate" :model="formBasic" :rules="ruleBasic" label-position="top">
              <FormItem label="数据名称" prop="dataName">
                <Input v-model="formBasic.dataName" placeholder="请输入" @on-focus="focus(0)"></Input>
              </FormItem>

              <FormItem label="一级分类" prop="first">
                <Select v-model="formBasic.first" @on-change="changeFirst" @on-open-change="focus(1)">
                  <Option :value="item.value" :lebel="item.label" v-for="item in firstList" :key="item.value"></Option>
                </Select>
              </FormItem>
              <FormItem label="二级分类" prop="second">
                <Select v-model="formBasic.second" @on-change="changeSecond" @on-open-change="focus(2)">
                  <Option :value="item.value" :lebel="item.label" v-for="item in secondList" :key="item.value"></Option>
                </Select>
              </FormItem>
              <FormItem label="三级分类" prop="third">
                <Select v-model="formBasic.third" @on-open-change="focus(3)">
                  <Option :value="item.value" :lebel="item.label" v-for="item in thirdList" :key="item.value"></Option>
                </Select>
              </FormItem>
              <FormItem label="四级分类">
                <Input v-model="formBasic.fourth" placeholder="请输入" @on-focus="focus(4)"></Input>
              </FormItem>
              <FormItem label="数据描述" prop="description">
                <Input v-model="formBasic.description" placeholder="请输入" @on-focus="focus(5)"></Input>
              </FormItem>
              <FormItem label="数据级别" prop="level">
                <Select v-model="formBasic.level" @on-open-change="focus(6)" disabled>
                  <Option value="重要数据">重要数据</Option>
                  <Option value="核心数据">核心数据</Option>
                </Select>
              </FormItem>
              <FormItem label="分级依据" prop="gradeBasic">
                <Input v-model="formBasic.gradeBasic" placeholder="请输入" @on-focus="focus(7)"></Input>
              </FormItem>
              <FormItem label="所在数据中心" prop="dataCenter">
                <Input v-model="formBasic.dataCenter" placeholder="请输入" @on-focus="focus(8)"></Input>
              </FormItem>

              <FormItem label="数据总量" prop="dataSize">
                <Input v-model="formBasic.dataSize" placeholder="请输入" type="number" @on-focus="focus(9)"></Input>
              </FormItem>
              <FormItem label="数据记录数" prop="dataCount">
                <Input v-model="formBasic.dataCount" placeholder="请输入" @on-focus="focus(10)"></Input>
              </FormItem>
              <FormItem label="数据主要来源" prop="source">
                <Select v-model="formBasic.source" @on-open-change="focus(11)">
                  <Option value="大科学装置">大科学装置</Option>
                  <Option value="野外台站">野外台站</Option>
                  <Option value="科研仪器">科研仪器</Option>
                  <Option value="调查研究">调查研究</Option>
                  <Option value="计算模拟">计算模拟</Option>
                  <Option value="业务处理信息系统">业务处理信息系统</Option>
                  <Option value="数据托管">数据托管</Option>
                  <Option value="第三方购买">第三方购买</Option>
                  <Option value="数据共享">数据共享</Option>
                  <Option value="公开渠道获取">公开渠道获取</Option>
                  <Option value="数据汇交">数据汇交</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </FormItem>
              <FormItem label="数据精度">
                <Input v-model="formBasic.precision" placeholder="请输入" @on-focus="focus(12)"></Input>
              </FormItem>

              <FormItem label="数据覆盖类型" prop="cover">
                <Select v-model="formBasic.cover" @on-open-change="focus(13)">
                  <Option value="领域">领域</Option>
                  <Option value="群体">群体</Option>
                  <Option value="区域">区域</Option>
                </Select>
              </FormItem>
              <FormItem label="数据覆盖具体情况" prop="coverDetail">
                <Input v-model="formBasic.coverDetail" placeholder="请输入" @on-focus="focus(14)"></Input>
              </FormItem>
              <FormItem label="数据覆盖占比" prop="ratio">
                <template style="display:flex;align-items:center">
                  <Input v-model="formBasic.ratio" placeholder="请输入" @on-focus="focus(15)"></Input> %
                </template>
              </FormItem>
              <FormItem label="数据覆盖占比测算依据" prop="ratioCalculationBasis">
                <Input v-model="formBasic.ratioCalculationBasis" placeholder="请输入" @on-focus="focus(16)"></Input>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="part">
          <div class="title margin-20">
            <img src="../../assets/img/责任主体-已填写.png" alt="" />
            <span>数据载体情况</span>
          </div>
          <div class="form">
            <Form ref="formPartValidate" :model="formPart" :rules="rulePart" label-position="top">
              <FormItem label="数据载体" prop="payload">
                <Select v-model="formPart.payload" @on-open-change="focus(17)">
                  <Option value="与互联网连接的信息系统">与互联网连接的信息系统</Option>
                  <Option value="不与互联网连接的信息系统">不与互联网连接的信息系统</Option>
                  <Option value="其他电子化数据">其他电子化数据</Option>
                  <Option value="非电子化数据">非电子化数据</Option>
                </Select>
              </FormItem>
              <FormItem label="信息系统名称" prop="appName" v-if="
                  formPart.payload == '不与互联网连接的信息系统' ||
                  formPart.payload == '与互联网连接的信息系统'
                ">
                <Input v-model="formPart.appName" placeholder="请输入" @on-focus="focus(18)"></Input>
              </FormItem>
              <FormItem label="信息系统名称" v-else>
                <Input v-model="formPart.appName" placeholder="请输入" @on-focus="focus(18)"></Input>
              </FormItem>
              <FormItem label="服务网站域名" prop="domainName" v-if="formPart.payload == '与互联网连接的信息系统'">
                <Input v-model="formPart.domainName" placeholder="请输入" @on-focus="focus(19)"></Input>
              </FormItem>
              <FormItem label="服务网站域名" v-else>
                <Input v-model="formPart.domainName" placeholder="请输入" @on-focus="focus(19)"></Input>
              </FormItem>
              <FormItem label="服务网站IP地址" prop="webIpAddress" v-if="formPart.payload == '与互联网连接的信息系统'">
                <Input v-model="formPart.webIpAddress" placeholder="请输入" @on-focus="focus(20)"></Input>
              </FormItem>
              <FormItem label="服务网站IP地址" prop="webIpAddress" v-else>
                <Input v-model="formPart.webIpAddress" placeholder="请输入" @on-focus="focus(20)"></Input>
              </FormItem>
              <FormItem label="服务网站IP端口" prop="webIpPort" v-if="formPart.payload == '与互联网连接的信息系统'">
                <Input v-model="formPart.webIpPort" type="number" placeholder="请输入" @on-focus="focus(21)"></Input>
              </FormItem>
              <FormItem label="服务网站IP端口" prop="webIpPort" v-else>
                <Input v-model="formPart.webIpPort" placeholder="请输入" type="number" @on-focus="focus(21)"></Input>
              </FormItem>
              <FormItem label="主机IP地址" prop="hostIpAddress" v-if="
                  formPart.payload == '不与互联网连接的信息系统' ||
                  formPart.payload == '与互联网连接的信息系统'
                ">
                <Input v-model="formPart.hostIpAddress" placeholder="请输入" @on-focus="focus(22)"></Input>
              </FormItem>
              <FormItem label="主机IP地址" prop="hostIpAddress" v-else>
                <Input v-model="formPart.hostIpAddress" placeholder="请输入" @on-focus="focus(22)"></Input>
              </FormItem>
              <FormItem label="主机端口" prop="hostIpPort" v-if="
                  formPart.payload == '不与互联网连接的信息系统' ||
                  formPart.payload == '与互联网连接的信息系统'
                ">
                <Input v-model="formPart.hostIpPort" placeholder="请输入" type="number" @on-focus="focus(23)"></Input>
              </FormItem>
              <FormItem label="主机端口" prop="hostIpPort" v-else>
                <Input v-model="formPart.hostIpPort" placeholder="请输入" type="number" @on-focus="focus(23)"></Input>
              </FormItem>
              <FormItem label="是否进行ICP备案" prop="icpFilings" v-if="formPart.payload == '与互联网连接的信息系统'">
                <Select v-model="formPart.icpFilings" @on-open-change="focus(24)">
                  <Option value="是">是</Option>
                  <Option value="否">否</Option>
                </Select>
              </FormItem>
              <FormItem label="是否进行ICP备案" v-else>
                <Select v-model="formPart.icpFilings" @on-open-change="focus(24)">
                  <Option value="是">是</Option>
                  <Option value="否">否</Option>
                </Select>
              </FormItem>
              <FormItem label="网络安全等级保护情况" prop="protection" v-if="
                  formPart.payload == '不与互联网连接的信息系统' ||
                  formPart.payload == '与互联网连接的信息系统'
                ">
                <Select v-model="formPart.protection" @on-open-change="focus(25)">
                  <Option value="一级">一级</Option>
                  <Option value="二级">二级</Option>
                  <Option value="三级">三级</Option>
                  <Option value="四级">四级</Option>
                  <Option value="五级">五级</Option>
                  <Option value="不涉及">不涉及</Option>
                </Select>
              </FormItem>
              <FormItem label="网络安全等级保护情况" v-else>
                <Select v-model="formPart.protection" @on-open-change="focus(25)">
                  <Option value="一级">一级</Option>
                  <Option value="二级">二级</Option>
                  <Option value="三级">三级</Option>
                  <Option value="四级">四级</Option>
                  <Option value="五级">五级</Option>
                  <Option value="不涉及">不涉及</Option>
                </Select>
              </FormItem>
              <FormItem label="是否为关键信息基础设施" prop="infrastructure" v-if="
                  formPart.payload == '不与互联网连接的信息系统' ||
                  formPart.payload == '与互联网连接的信息系统'
                ">
                <Select v-model="formPart.infrastructure" @on-open-change="focus(26)">
                  <Option value="是">是</Option>
                  <Option value="否">否</Option>
                </Select>
              </FormItem>
              <FormItem label="是否为关键信息基础设施" v-else>
                <Select v-model="formPart.infrastructure" @on-open-change="focus(26)">
                  <Option value="是">是</Option>
                  <Option value="否">否</Option>
                </Select>
              </FormItem>
            </Form>
          </div>
        </div>

        <div class="info" id="dealWith">
          <div class="title margin-20">
            <img src="../../assets/img/处理情况-已填写.png" alt="" />
            <span>数据共享及出境情况</span>
          </div>
          <div class="form">
            <Form ref="formDealWithValidate" :model="formDealWith" :rules="ruleDealWith" label-position="top">
              <FormItem label="数据处理目的" prop="purpose">
                <Input v-model="formDealWith.purpose" placeholder="请输入" @on-focus="focus(27)"></Input>
              </FormItem>
              <FormItem label="是否涉及跨主体流动" prop="flows">
                <Select v-model="formDealWith.flows" @on-open-change="focus(28)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="共享方式" prop="sharingMode">
                <Select v-model="formDealWith.sharingMode" @on-open-change="focus(29)">
                  <Option value="完全公开">完全公开</Option>
                  <Option value="有条件共享">有条件共享</Option>
                  <Option value="不共享">不共享</Option>
                </Select>
              </FormItem>
              <FormItem label="共享范围" prop="shareRange" v-if="
                  formDealWith.sharingMode == '完全公开' ||
                  formDealWith.sharingMode == '有条件共享'
                ">
                <Input v-model="formDealWith.shareRange" placeholder="请输入" @on-focus="focus(30)"></Input>
              </FormItem>
              <FormItem label="共享范围" v-else>
                <Input v-model="formDealWith.shareRange" placeholder="请输入" @on-focus="focus(30)"></Input>
              </FormItem>
              <FormItem label="是否有涉外数据" prop="foreign">
                <Select v-model="formDealWith.foreign" @on-open-change="focus(31)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="涉外数据来源情况" prop="foreignDataSource" v-if="formDealWith.foreign == '是'">
                <Input v-model="formDealWith.foreignDataSource" placeholder="请输入" @on-focus="focus(32)"></Input>
              </FormItem>
              <FormItem label="涉外数据来源情况" v-else>
                <Input v-model="formDealWith.foreignDataSource" placeholder="请输入" @on-focus="focus(32)"></Input>
              </FormItem>
              <FormItem label="是否出境" prop="abroad">
                <Select v-model="formDealWith.abroad" @on-open-change="focus(33)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="出境方式" prop="exitMode" v-if="formDealWith.abroad == '是'">
                <Select v-model="formDealWith.exitMode" @on-open-change="focus(34)">
                  <Option value="签订合作协议">签订合作协议</Option>
                  <Option value="开放获取">开放获取</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </FormItem>
              <FormItem label="出境方式" v-else>
                <Select v-model="formDealWith.exitMode" @on-open-change="focus(34)">
                  <Option value="签订合作协议">签订合作协议</Option>
                  <Option value="开放获取">开放获取</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </FormItem>
              <FormItem label="境外接收方" prop="overseasReceiver" v-if="formDealWith.exitMode == '签订合作协议'">
                <Input v-model="formDealWith.overseasReceiver" placeholder="请输入" @on-focus="focus(35)"></Input>
              </FormItem>
              <FormItem label="境外接收方" v-else>
                <Input v-model="formDealWith.overseasReceiver" placeholder="请输入" @on-focus="focus(35)"></Input>
              </FormItem>
              <FormItem label="是否完成数据出境安全评估" prop="safetyAssessment" v-if="formDealWith.abroad == '是'">
                <Select v-model="formDealWith.safetyAssessment" @on-open-change="focus(36)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="是否完成数据出境安全评估" v-else>
                <Select v-model="formDealWith.safetyAssessment" @on-open-change="focus(36)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="数据出境安全评估结果" prop="assessmentResult" v-if="formDealWith.safetyAssessment == '是'">
                <Input v-model="formDealWith.assessmentResult" placeholder="请输入" @on-focus="focus(37)"></Input>
              </FormItem>
              <FormItem label="数据出境安全评估结果" v-else>
                <Input v-model="formDealWith.assessmentResult" placeholder="请输入" @on-focus="focus(37)"></Input>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="safe">
          <div class="title margin-20">
            <img src="../../assets/img/数据安全-已填写.png" alt="" />
            <span>数据安全情况</span>
          </div>
          <div class="form">
            <Form ref="formSafeValidate" :model="formSafe" :rules="ruleSafe" label-position="top">
              <FormItem label="安全负责人姓名" prop="chargeName">
                <Input v-model="formSafe.chargeName" @on-focus="focus(38)" placeholder="请输入"></Input>
              </FormItem>
              <FormItem label="安全负责人职务" prop="chargeTitle">
                <Input v-model="formSafe.chargeTitle" placeholder="请输入" @on-focus="focus(39)"></Input>
              </FormItem>
              <FormItem label="安全负责人联系方式" prop="chargeContact">
                <Input v-model="formSafe.chargeContact" placeholder="请输入" @on-focus="focus(40)"></Input>
              </FormItem>
              <FormItem label="是否进行数据安全风险评估" prop="assessment">
                <Select v-model="formSafe.assessment" @on-open-change="focus(41)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="数据安全风险评估机构" prop="evaluationOrg" v-if="formSafe.assessment == '是'">
                <Input v-model="formSafe.evaluationOrg" placeholder="请输入" @on-focus="focus(42)"></Input>
              </FormItem>
              <FormItem label="数据安全风险评估机构" v-else>
                <Input v-model="formSafe.evaluationOrg" placeholder="请输入" @on-focus="focus(42)"></Input>
              </FormItem>
              <FormItem label="数据安全风险评估依据规范" prop="specification" v-if="formSafe.assessment == '是'">
                <Input v-model="formSafe.specification" placeholder="请输入" @on-focus="focus(43)"></Input>
              </FormItem>
              <FormItem label="数据安全风险评估依据规范" v-else>
                <Input v-model="formSafe.specification" placeholder="请输入" @on-focus="focus(43)"></Input>
              </FormItem>
              <FormItem label="数据安全风险评估时间" prop="evaluationDate" v-if="formSafe.assessment == '是'">
                <Input v-model="formSafe.evaluationDate" placeholder="请输入" @on-focus="focus(44)"></Input>
              </FormItem>
              <FormItem label="数据安全风险评估时间" prop="evaluationDate" v-else>
                <Input v-model="formSafe.evaluationDate" placeholder="请输入" @on-focus="focus(44)"></Input>
              </FormItem>
              <FormItem label="数据安全风险评估结论" prop="conclusion" v-if="formSafe.assessment == '是'">
                <Input v-model="formSafe.conclusion" placeholder="请输入" @on-focus="focus(45)"></Input>
              </FormItem>
              <FormItem label="数据安全风险评估结论" v-else>
                <Input v-model="formSafe.conclusion" placeholder="请输入" @on-focus="focus(45)"></Input>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="duty">
          <div class="title margin-20">
            <img src="../../assets/img/责任主体-已填写.png" alt="" />
            <span>责任主体情况</span>
          </div>
          <div class="form">
            <Form ref="formDutyValidate" :model="formDuty" :rules="ruleDuty" label-position="top">
              <FormItem label="数据处理者名称" prop="processor">
                <Input v-model="formDuty.processor" placeholder="请输入" @on-focus="focus(46)"></Input>
              </FormItem>
              <FormItem label="机构代码" prop="orgCode">
                <Input v-model="formDuty.orgCode" placeholder="请输入" @on-focus="focus(47)"></Input>
              </FormItem>
              <FormItem label="省份" prop="province">
                <Select v-model="formDuty.province" @on-change="changeProvince" @on-open-change="focus(48)">
                  <Option :value="item.name" v-for="item in province" :key="item.name">{{ item.name }}</Option>
                </Select>
              </FormItem>
              <FormItem label="市" prop="city">
                <Select v-model="formDuty.city" @on-open-change="focus(49)">
                  <Option :value="item.name" v-for="item in citys" :key="item.name">{{ item.name }}</Option>
                </Select>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="audit">
          <div class="title margin-20">
            <img src="../../assets/img/数据安全-已填写.png" alt="" />
            <span>数据目录审核情况</span>
          </div>
          <div class="form">
            <Form ref="formAuditValidate" :model="formAudit" :rules="ruleAudit" label-position="top">
              <FormItem label="是否经过专家评审" prop="reviewed">
                <Select v-model="formAudit.reviewed" @on-open-change="focus(50)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="是否经过学科领域专家评审" prop="fieldReviewed" v-if="formAudit.reviewed == '是'">
                <Select v-model="formAudit.fieldReviewed" @on-open-change="focus(51)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="是否经过学科领域专家评审" v-else>
                <Select v-model="formAudit.fieldReviewed" @on-open-change="focus(51)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="是否经过数据管理专家评审" prop="managerReviewed" v-if="formAudit.reviewed == '是'">
                <Select v-model="formAudit.managerReviewed" @on-open-change="focus(52)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="是否经过数据管理专家评审" v-else>
                <Select v-model="formAudit.managerReviewed" @on-open-change="focus(52)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="是否经过信息化管理专家评审" prop="informationReviewed" v-if="formAudit.reviewed == '是'">
                <Select v-model="formAudit.informationReviewed" @on-open-change="focus(53)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="是否经过信息化管理专家评审" v-else>
                <Select v-model="formAudit.informationReviewed" @on-open-change="focus(53)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
              <FormItem label="是否经过法定代表人审定" prop="legalReviewed">
                <Select v-model="formAudit.legalReviewed" @on-open-change="focus(54)">
                  <Option :value="item.value" v-for="item in options" :key="item.value" :label="item.label"></Option>
                </Select>
              </FormItem>
            </Form>
          </div>
        </div>
        <div class="info" id="remark">
          <div class="title margin-20">
            <img src="../../assets/img/备注补充-已填写.png" alt="" />
            <span>备注补充</span>
          </div>
          <div class="form remark">
            <label for="">备注</label>
            <Input v-model="remark" type="textarea" :rows="4" placeholder="请输入" @on-focus="focus(55)" />
          </div>
        </div>
        <div class="footer">
          <div class="btn btn-simple margin-right-10" @click="modal1 = true">
            取消
          </div>
          <!-- <div class="btn btn-simple margin-right-10" @click="Staging">
            暂存草稿
          </div> -->
          <div class="btn btn-default" @click="submitData" v-if="!isEdit">
            保存
          </div>
          <div class="btn btn-default" @click="updateData" v-if="isEdit">
            保存
          </div>
        </div>
      </div>
      <div class="point">
        <div class="point-content">
          <h2>{{ title }}</h2>
          <!-- <div class="tip">{{ tip }}</div> -->
          <p>{{ desc }}</p>
        </div>
      </div>
    </div>
    <BackTop :bottom="100"></BackTop>
    <Modal v-model="modal1" sticky scrollable class="custom-modal-footer-block" @on-cancel="modal1 = false">
      <template #header>
        <p class="title">提醒</p>
        <div class="modal-close" @click="modal1 = false">
          关闭 <span>ESC</span>
        </div>
      </template>
      <div class="alarm-info">你确定要取消吗？</div>
      <template #footer>
        <div class="btn btn-default" @click="handleReset">确认</div>
      </template>
    </Modal>
    <!-- <uLoading ></uLoading> -->
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  watch,
  onMounted,
  nextTick,
  onBeforeUnmount,
  inject,
} from "vue";
import province from "@/utils/province";
import { Message, Modal } from "view-ui-plus";
import { useRouter, useRoute } from "vue-router";
import CommonHeader from "@/components/commonHeader.vue";
import uLoading from "u-loading";
import { ElLoading } from "element-plus";

let router = useRouter();
let route = useRoute();
let id = ref(""); //暂存项目的id

let loading = ref(false);
let modal1 = ref(false);

const handleBack = () => {
  router.back();
};
//导航信息
let nav = [
  {
    title: "基本信息",
    value: "basic",
  },
  {
    title: "载体情况",
    value: "part",
  },
  {
    title: "共享及出境情况",
    value: "dealWith",
  },
  {
    title: "数据安全",
    value: "safe",
  },
  {
    title: "责任主体",
    value: "duty",
  },
  {
    title: "目录审核情况",
    value: "audit",
  },
  {
    title: "备注补充",
    value: "remark",
  },
];
let options = [
  {
    label: "是",
    value: "是",
  },
  {
    label: "否",
    value: "否",
  },
];
//验证数据是否填写完整
let isCompleteBasic = ref(false);
let formBasic = ref({
  dataName: "",
  first: "",
  second: "",
  third: "",
  fourth: "",
  description: "",
  level: "重要数据",
  gradeBasic: "",
  dataCenter: "",
  source: "",
  dataSize: "",
  dataCount: "",
  cover: "",
  ratio: "",
  precision: "",
  coverDetail: "",
  ratioCalculationBasis: "",
});

const validateDesc = (rule, value, callback) => {
  if (value == "") {
    callback("数据描述不能为空");
  } else {
    if (value.length < 50) {
      callback("数据描述不能少于50字");
    }
    callback();
  }
};

// 只能是数字
const validatehello = (rule, value, callback) => {
  if (value == "") {
    callback(new Error("数据覆盖占比不能为空"));
  } else {
    if (!isNaN(value)) { // 判断是否为数字
      if (value > 0 && value < 10000) {
        const regex = /^\d+(\.\d{1,10})?$/;
        if (regex.test(value)) {
          callback();
        } else {
          callback(new Error("小数点后不得超过10位！"));
        }

      } else {
        callback(new Error("输入值为0-10000之间的数字！"));
      }
    } else {
      callback(new Error("输入值为0-10000之间的数字！"));
    }
  }
};
// 只能是数字
const validatehello2 = (rule, value, callback) => {
  if (value == "") {
    callback(new Error("数据总量不能为空"));
  } else {
    if (value > 0 && value < 100000000000000000000000000000000000000000000000000000000000) {
      const regex = /^\d+(\.\d{1,10})?$/;
      if (regex.test(value)) {
        callback();
      } else {
        callback(new Error("小数点后不得超过10位！"));
      }

    } else {
      callback(new Error("输入值过大 ，请查看是否正确！"));
    }
  }
};
let ruleBasic = {
  dataName: [
    {
      required: true,
      message: "数据名称不能为空",
      trigger: "blur",
    },
  ],
  standard: [
    {
      required: true,
      message: "依据数据分类分级规范不能为空",
      trigger: "blur",
    },
  ],
  first: [
    {
      required: true,
      message: "一级分类不能为空",
      trigger: "change",
    },
  ],
  second: [
    {
      required: true,
      message: "二级分类不能为空",
      trigger: "change",
    },
  ],
  third: [
    {
      required: true,
      message: "三级分类不能为空",
      trigger: "change",
    },
  ],
  description: [
    {
      required: true,
      validator: validateDesc,
      trigger: "blur",
    },
  ],
  level: [
    {
      required: true,
      message: "数据级别不能为空",
      trigger: "change",
    },
  ],
  gradeBasic: [
    {
      required: true,
      message: "分级依据不能为空",
      trigger: "blur",
    },
  ],
  dataCenter: [
    {
      required: true,
      message: "所在数据中心不能为空",
      trigger: "blur",
    },
  ],
  source: [
    {
      required: true,
      message: "数据主要来源不能为空",
      trigger: "change",
    },
  ],
  dataSize: [
    {
      required: true,
      // message: "数据总量不能为空",
      validator: validatehello2,
      trigger: "blur",
    },
  ],
  dataCount: [
    {
      required: true,
      message: "数据记录数不能为空",
      trigger: "blur",
    },
  ],
  cover: [
    {
      required: true,
      message: "数据覆盖类型不能为空",
      trigger: "change",
    },
  ],
  coverDetail: [
    {
      required: true,
      message: "数据覆盖具体情况不能为空",
      trigger: "blur",
    },
  ],
  ratio: [
    {
      required: true,
      // message: "数据覆盖占比不能为空",
      validator: validatehello,
      trigger: "blur",
    },
  ],
  ratioCalculationBasis: [
    {
      required: true,
      message: "数据覆盖占比测算依据不能为空",
      trigger: "blur",
    },
  ],
};

let firstList = ref([]);
let secondList = ref([]);
let thirdList = ref([]);
//获取类别数据
const getLevelData = () => {
  let url = `/content/index`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      firstList.value = res.data.data;
    }
  });
};

//更改一级类别
const changeFirst = (name) => {
  firstList.value.forEach((item) => {
    if (item.label == name) {
      secondList.value = item.children;
      thirdList.value = [];
      formBasic.value.second = "";
      formBasic.value.third = "";
    }
  });
};
//更改二级类别
const changeSecond = (name) => {
  secondList.value.forEach((item) => {
    if (item.label == name) {
      thirdList.value = item.children;
      formBasic.value.third = "";
    }
  });
};

let formBasicValidate = ref(null);
//验证基本信息是否填写正确完整
const submitBasicData = () => {
  formBasicValidate.value.validate((valid) => {
    if (valid) {
      // console.log('first')
      isCompleteBasic.value = true;
      // this.$Message.success("Success!");
    } else {
      // Message.error("请检查数据填写数据是否完整!");
    }
  });
};

let formPart = ref({
  payload: "",
  appName: "",
  domainName: "",
  webIpAddress: "",
  webIpPort: "",
  hostIpAddress: "",
  hostIpPort: "",
  icpFilings: "",
  protection: "",
  infrastructure: "",
});


// ip正则
let regIp = /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/;
const validateIP = (rule, value, callback) => {
  if (value == "") {
    if (formPart.value.payload == '与互联网连接的信息系统') {
      callback(new Error("服务网站IP地址不能为空"));
    } else {
      callback();
    }
  } else {
    let result = value.replace(/，/gi, ',');
    let arr = result.split(',')
    let isTrue = true
    arr.forEach((item) => {
      if (!regIp.test(item)) {
        isTrue = false
      }
    })
    if (isTrue) {
      callback();
    } else {
      callback(new Error("服务网站IP地址不符合规则,请检查IP地址和分隔符是否正确"));
    }
    // if (!regIp.test(value)) {
    //   callback(new Error("服务网站IP地址不符合规则"));
    // } else {
    //   callback();
    // }
  }
};
// ip正则
const validateIP2 = (rule, value, callback) => {
  if (value == "") {
    if (formPart.value.payload == '与互联网连接的信息系统' || formPart.value.payload == '不与互联网连接的信息系统') {
      callback(new Error("主机IP地址不能为空"));
    } else {
      callback();
    }
  } else {
    let result = value.replace(/，/gi, ',');
    let arr = result.split(',')
    let isTrue = true
    arr.forEach((item) => {
      if (!regIp.test(item)) {
        isTrue = false
      }
    })
    if (isTrue) {
      callback();
    } else {
      callback(new Error("主机IP地址不符合规则,请检查IP地址和分隔符是否正确"));
    }
    // if (!regIp.test(value)) {
    //   callback(new Error("主机IP地址不符合规则"));
    // } else {
    //   callback();
    // }
  }
};

// 端口号正则
let regPort = /^\d{1,5}$/;
const validatePort = (rule, value, callback) => {
  if (value == "") {
    // callback(new Error("服务网站IP端口不能为空"));
    if (formPart.value.payload == '与互联网连接的信息系统') {
      callback(new Error("服务网站IP端口不能为空"));
    } else {
      callback();
    }
  } else {
    if (!regPort.test(value)) {
      callback(new Error("服务网站IP端口不符合规则"));
    } else {
      callback();
    }
  }
};
const validatePort2 = (rule, value, callback) => {
  if (value == "") {
    // callback(new Error("主机端口不能为空"));
    if (formPart.value.payload == '与互联网连接的信息系统' || formPart.value.payload == '不与互联网连接的信息系统') {
      callback(new Error("主机端口不能为空"));
    } else {
      callback();
    }
  } else {
    if (!regPort.test(value)) {
      callback(new Error("主机端口不符合规则"));
    } else {
      callback();
    }
  }
};


const rulePart = ref({
  payload: [
    {
      required: true,
      message: "数据载体不能为空",
      trigger: "change",
    },
  ],
  appName: [
    {
      required: true,
      message: "信息系统名称不能为空",
      trigger: "blur",
    },
  ],
  domainName: [
    {
      required: true,
      message: "服务网站域名不能为空",
      trigger: "blur",
    },
  ],
  webIpAddress: [
    {
      required: formPart.value.payload == '与互联网连接的信息系统' ? true : false,
      // message: "服务网站IP地址不能为空",
      validator: validateIP,
      trigger: "blur",
    },
  ],
  webIpPort: [
    {
      required: formPart.value.payload == '与互联网连接的信息系统' ? true : false,
      // message: "服务网站IP端口不能为空",
      validator: validatePort,
      trigger: "blur",
    },
  ],
  hostIpAddress: [
    {
      required: (formPart.value.payload == '与互联网连接的信息系统' || formPart.value.payload == '不与互联网连接的信息系统') ? true : false,
      // message: "主机IP地址不能为空",
      validator: validateIP2,
      trigger: "blur",
    },
  ],
  hostIpPort: [
    {
      required: (formPart.value.payload == '与互联网连接的信息系统' || formPart.value.payload == '不与互联网连接的信息系统') ? true : false,
      // message: "主机端口不能为空",
      validator: validatePort2,
      trigger: "blur",
    },
  ],
  icpFilings: [
    {
      required: true,
      message: "是否进行ICP备案不能为空",
      trigger: "change",
    },
  ],
  protection: [
    {
      required: true,
      message: "网络安全等级保护情况不能为空",
      trigger: "change",
    },
  ],
  infrastructure: [
    {
      required: true,
      message: "关键信息基础设施不能为空",
      trigger: "change",
    },
  ],
});


watch(
  () => formPart.value.payload,
  (val) => {
    rulePart.value = {
      payload: [
        {
          required: true,
          message: "数据载体不能为空",
          trigger: "change",
        },
      ],
      appName: [
        {
          required: true,
          message: "信息系统名称不能为空",
          trigger: "blur",
        },
      ],
      domainName: [
        {
          required: true,
          message: "服务网站域名不能为空",
          trigger: "blur",
        },
      ],
      webIpAddress: [
        {
          required: val == '与互联网连接的信息系统' ? true : false,
          // message: "服务网站IP地址不能为空",
          validator: validateIP,
          trigger: "blur",
        },
      ],
      webIpPort: [
        {
          required: val == '与互联网连接的信息系统' ? true : false,
          // message: "服务网站IP端口不能为空",
          validator: validatePort,
          trigger: "blur",
        },
      ],
      hostIpAddress: [
        {
          required: (val == '与互联网连接的信息系统' || val == '不与互联网连接的信息系统') ? true : false,
          // message: "主机IP地址不能为空",
          validator: validateIP2,
          trigger: "blur",
        },
      ],
      hostIpPort: [
        {
          required: (val == '与互联网连接的信息系统' || val == '不与互联网连接的信息系统') ? true : false,
          // message: "主机端口不能为空",
          validator: validatePort2,
          trigger: "blur",
        },
      ],
      icpFilings: [
        {
          required: true,
          message: "是否进行ICP备案不能为空",
          trigger: "change",
        },
      ],
      protection: [
        {
          required: true,
          message: "网络安全等级保护情况不能为空",
          trigger: "change",
        },
      ],
      infrastructure: [
        {
          required: true,
          message: "关键信息基础设施不能为空",
          trigger: "change",
        },
      ],
    }
  },
  { deep: true }
);



let isCompletePart = ref(false);
let formPartValidate = ref(null);
//验证基本信息是否填写正确完整
const submitPartData = () => {
  formPartValidate.value.validate((valid) => {
    if (valid) {
      isCompletePart.value = true;
    } else {
      // Message.error("请检查数据填写数据是否完整!");
    }
  });
};

let formDealWith = ref({
  purpose: "",
  abroad: "",
  foreignDataSource: "",
  exitMode: "",
  flows: "",
  foreign: "",
  sharingMode: "",
  shareRange: "",
  foreignDataSource: "",
  overseasReceiver: "",
  safetyAssessment: "",
  assessmentResult: "",
});
let ruleDealWith = {
  purpose: [
    {
      required: true,
      message: "数据处理目的不能为空",
      trigger: "blur",
    },
  ],
  abroad: [
    {
      required: true,
      message: "是否出境不能为空",
      trigger: "change",
    },
  ],
  sharingMode: [
    {
      required: true,
      message: "共享方式不能为空",
      trigger: "change",
    },
  ],
  flows: [
    {
      required: true,
      message: "是否涉及跨主体流动不能为空",
      trigger: "change",
    },
  ],
  shareRange: [
    {
      required: true,
      message: "共享范围不能为空",
      trigger: "blur",
    },
  ],
  foreign: [
    {
      required: true,
      message: "是否有涉外数据不能为空",
      trigger: "change",
    },
  ],
  shareRange: [
    {
      required: true,
      message: "共享范围不能为空",
      trigger: "blur",
    },
  ],
  foreignDataSource: [
    {
      required: true,
      message: "涉外数据来源情况不能为空",
      trigger: "blur",
    },
  ],
  abroad: [
    {
      required: true,
      message: "是否出境不能为空",
      trigger: "change",
    },
  ],
  exitMode: [
    {
      required: true,
      message: "出境方式不能为空",
      trigger: "change",
    },
  ],
  overseasReceiver: [
    {
      required: true,
      message: "境外接收方不能为空",
      trigger: "blur",
    },
  ],
  safetyAssessment: [
    {
      required: true,
      message: "是否完成数据出境安全评估不能为空",
      trigger: "change",
    },
  ],
  assessmentResult: [
    {
      required: true,
      message: "数据出境安全评估结果不能为空",
      trigger: "blur",
    },
  ],
};
let isCompleteDealWith = ref(false);
let formDealWithValidate = ref(null);
//验证基本信息是否填写正确完整
const submitDealWithData = () => {
  formDealWithValidate.value.validate((valid) => {
    if (valid) {
      isCompleteDealWith.value = true;
    } else {
      // Message.error("请检查数据填写数据是否完整!");
    }
  });
};

let formSafe = ref({
  chargeName: "",
  chargeTitle: "",
  chargeContact: "",
  assessment: "",
  evaluationOrg: "",
  specification: "",
  evaluationDate: "",
  conclusion: "",
});
const validateDate = (rule, value, callback) => {
  if (value == "") {
    if (formSafe.value.assessment == '是') {
      callback("请输入评估时间");
    } else {
      callback();
    }

  } else {
    //2023年10月8日   2023年11月2日
    if (value.includes('年') && value.includes('月') && value.includes('日')) {
      let newValue = value.split(/[年月日]/)
      if (newValue.length != 4) {  // 判断一下是否含有年月日
        callback("评估时间不符合格式");
      } else {
        if (!isNaN(newValue[0]) && !isNaN(newValue[1]) && !isNaN(newValue[2])) { // 判断是否都为数字
          newValue[0] = Number(newValue[0])
          newValue[1] = Number(newValue[1])
          newValue[2] = Number(newValue[2])
          if (isPositiveInteger(newValue[0]) && isPositiveInteger(newValue[1]) && isPositiveInteger(newValue[2])) { // 判断是否都为正整数
            if (newValue[0] > 1950 && newValue[0] < 2024) { //判断年份
              if (newValue[1] > 0 && newValue[1] < 13) { //判断月份
                if (newValue[2] > 0 && newValue[2] < 32) { //判断日
                  if (newValue[0] == 2023) {
                    if (newValue[1] < 12) {
                      if (newValue[1] == 11) {
                        if (newValue[2] > 25) {
                          callback("不能晚于系统开放填报日期");
                        } else {
                          callback();
                        }
                      } else {
                        callback();
                      }
                    } else {
                      callback("评估时间不符合格式");
                    }
                  } else {
                    callback();
                  }
                } else {
                  callback("评估时间不符合格式");
                }
              } else {
                callback("评估时间不符合格式");
              }
            } else {
              callback("评估时间不符合格式");
            }
          } else {
            callback("评估时间不符合格式");
          }
        } else {
          callback("评估时间不符合格式");
        }
        // let isTrue = true

      }
    } else {
      callback("评估时间不符合格式");
    }

    // if (!dateToTimestamp(value)) {


    //   callback("评估时间不符合格式");
    // } else {
    //   callback();
    // }
  }
};

const dateToTimestamp = (str) => {
  // if (str.length != 11) {
  //   return false;
  // }
  var time = str.replace(/年/i, "-").replace(/月/i, "-").replace(/日/i, "");
  var arr = time.split("-");
  var timestamp = new Date(Date.UTC(arr[0], arr[1] - 1, arr[2]));
  var timestamp = timestamp.getTime();
  return isFinite(timestamp);
};

//判断一个数字是否为正整数
const isPositiveInteger = (num) => {
  return Number.isInteger(num) && num > 0;
}


let ruleSafe = ref({
  chargeName: [
    {
      required: true,
      message: "姓名不能为空",
      trigger: "blur",
    },
  ],
  chargeTitle: [
    {
      required: true,
      message: "职务不能为空",
      trigger: "blur",
    },
  ],
  chargeContact: [
    {
      required: true,
      message: "联系方式不能为空",
      trigger: "blur",
    },
    {
      pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
      message: "手机号码格式不正确",
      trigger: "blur",
    },
  ],
  assessment: [
    {
      required: true,
      message: "是否进行数据安全风险评估不能为空",
      trigger: "change",
    },
  ],
  evaluationOrg: [
    {
      required: true,
      message: "数据安全风险评估机构不能为空",
      trigger: "blur",
    },
  ],
  specification: [
    {
      required: true,
      message: "数据安全风险评估依据规范不能为空",
      trigger: "blur",
    },
  ],
  evaluationDate: [
    {
      required: formSafe.value.assessment == '是' ? true : false,
      // message: "评估时间不能为空",
      validator: validateDate,
      trigger: "blur",
    },
  ],
  conclusion: [
    {
      required: true,
      message: "数据安全风险评估结论不能为空",
      trigger: "blur",
    },
  ],
});

watch(
  () => formSafe.value.assessment,
  (val) => {
    ruleSafe.value = {
      chargeName: [
        {
          required: true,
          message: "姓名不能为空",
          trigger: "blur",
        },
      ],
      chargeTitle: [
        {
          required: true,
          message: "职务不能为空",
          trigger: "blur",
        },
      ],
      chargeContact: [
        {
          required: true,
          message: "联系方式不能为空",
          trigger: "blur",
        },
        {
          pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
          message: "手机号码格式不正确",
          trigger: "blur",
        },
      ],
      assessment: [
        {
          required: true,
          message: "是否进行数据安全风险评估不能为空",
          trigger: "change",
        },
      ],
      evaluationOrg: [
        {
          required: true,
          message: "数据安全风险评估机构不能为空",
          trigger: "blur",
        },
      ],
      specification: [
        {
          required: true,
          message: "数据安全风险评估依据规范不能为空",
          trigger: "blur",
        },
      ],
      evaluationDate: [
        {
          required: val == '是' ? true : false,
          // message: "评估时间不能为空",
          validator: validateDate,
          trigger: "blur",
        },
      ],
      conclusion: [
        {
          required: true,
          message: "数据安全风险评估结论不能为空",
          trigger: "blur",
        },
      ],
    }
  },
  { deep: true }
);



let isCompleteSafe = ref(false);
let formSafeValidate = ref(null);
//验证基本信息是否填写正确完整
const submitSafeData = () => {
  formSafeValidate.value.validate((valid) => {
    if (valid) {
      isCompleteSafe.value = true;
    } else {
      // Message.error("请检查数据填写数据是否完整!");
    }
  });
};

let formDuty = ref({
  processor: "",
  orgCode: "",
  province: "",
  city: "",
});
let ruleDuty = {
  processor: [
    {
      required: true,
      message: "数据处理者名称不能为空",
      trigger: "blur",
    },
  ],
  orgCode: [
    {
      required: true,
      message: "机构代码不能为空",
      trigger: "blur",
    },
  ],
  province: [
    {
      required: true,
      message: "省份不能为空",
      trigger: "change",
    },
  ],
  city: [
    {
      required: true,
      message: "市区不能为空",
      trigger: "change",
    },
  ],
};
let formDutyValidate = ref(null);
let isCompleteDuty = ref(false);
//验证基本信息是否填写正确完整
const submitDutyData = () => {
  formDutyValidate.value.validate((valid) => {
    if (valid) {
      isCompleteDuty.value = true;
      // this.$Message.success("Success!");
    } else {
      // Message.error("请检查数据填写数据是否完整!");
    }
  });
};

let formAudit = ref({
  reviewed: "",
  fieldReviewed: "",
  managerReviewed: "",
  informationReviewed: "",
  legalReviewed: "",
});
let ruleAudit = {
  reviewed: [
    {
      required: true,
      message: "是否经过专家评审不能为空",
      trigger: "change",
    },
  ],
  fieldReviewed: [
    {
      required: true,
      message: "是否经过学科领域专家评审不能为空",
      trigger: "change",
    },
  ],
  managerReviewed: [
    {
      required: true,
      message: "是否经过数据管理专家评审不能为空",
      trigger: "change",
    },
  ],
  informationReviewed: [
    {
      required: true,
      message: "是否经过信息化管理专家评审不能为空",
      trigger: "change",
    },
  ],
  legalReviewed: [
    {
      required: true,
      message: "是否经过法定代表人审定不能为空",
      trigger: "change",
    },
  ],
};
let formAuditValidate = ref(null);
let isCompleteAudit = ref(false);
//验证基本信息是否填写正确完整
const submitAuditData = () => {
  formAuditValidate.value.validate((valid) => {
    if (valid) {
      isCompleteAudit.value = true;
      // this.$Message.success("Success!");
    } else {
      // Message.error("请检查数据填写数据是否完整!");
    }
  });
};



let remark = ref("");
let isCompleteRemark = ref(false);
let citys = ref([]);
//选择省份修改市区
const changeProvince = (val) => {
  province.forEach((item) => {
    if (item.name == val) {
      citys.value = item.city;
    }
  });
};

let isEmail = ref(false);
let isPerson = ref(false);

let axios = inject("axios");

let isClick = ref(true);  // 是否可以点击
// 提交全部数据
const submitData = () => {
  if (isClick.value == true) {
    isClick.value = false
    submitBasicData();
    submitPartData();
    submitDealWithData();
    submitSafeData();
    submitDutyData();
    submitAuditData();
    if (
      !isCompleteBasic.value ||
      !isCompletePart.value ||
      !isCompleteDealWith.value ||
      !isCompleteSafe.value ||
      !isCompleteDuty.value ||
      !isCompleteAudit.value
    ) {
      isClick.value = true
      return Message.error({
        content: "请检查所填写的内容是否正确",
        background: true,
        duration: 3,
      });
    }
    openFullScreen();
    let url = `/content/submit`;
    formBasic.value.ratio = formBasic.value.ratio + "%"
    let params = {
      ...formBasic.value,
      ...formPart.value,
      ...formDealWith.value,
      ...formSafe.value,
      ...formDuty.value,
      ...formAudit.value,
      remark: remark.value,
      id: id.value,
      second: formBasic.value.second.split(","),
      third:
        formBasic.value.third != "" ? formBasic.value.third.split(",") : null,
      submitOrNot: 0,
    };
    axios
      .post(url, params, {
        headers: {
          "content-Type": "application/json",
        },
      })
      .then((res) => {
        Loading.value.close();
        isClick.value = true
        if (res.data.code == 200) {
          Message.success("提交成功");
          router.push({
            name: "FillingList",
          });
        } else {
          formBasic.value.ratio = formBasic.value.ratio.split('%')[0]
          Message.error({
            content: res.data.message,
            background: true,
            duration: 3,
          });
        }
      });
  }

};
//修改数据
const updateData = () => {
  if (isClick.value == true) {
    isClick.value = false
    submitBasicData();
    submitPartData();
    submitDealWithData();
    submitSafeData();
    submitDutyData();
    submitAuditData();
    if (
      !isCompleteBasic.value ||
      !isCompletePart.value ||
      !isCompleteDealWith.value ||
      !isCompleteSafe.value ||
      !isCompleteDuty.value ||
      !isCompleteAudit.value
    ) {
      isClick.value = true
      return Message.error({
        content: "请检查所填写的内容是否正确",
        background: true,
        duration: 3,
      });
    }
    openFullScreen();
    let url = `/content/update`;
    formBasic.value.ratio = formBasic.value.ratio + "%"

    let params = {
      ...formBasic.value,
      ...formPart.value,
      ...formDealWith.value,
      ...formSafe.value,
      ...formDuty.value,
      ...formAudit.value,
      remark: remark.value,
      id: id.value,
      second: formBasic.value.second.split(","),
      third:
        formBasic.value.third != "" ? formBasic.value.third.split(",") : null,
    };
    axios
      .post(url, params, {
        headers: {
          "content-Type": "application/json",
        },
      })
      .then((res) => {
        Loading.value.close();
        isClick.value = true
        if (res.data.code == 200) {
          Message.success("修改成功");
          router.push({
            name: "FillingList",
          });
        } else {
          formBasic.value.ratio = formBasic.value.ratio.split('%')[0]
          Message.error({
            content: res.data.message,
            background: true,
            duration: 3,
          });
        }
      });
  }

};
//暂存草稿
const Staging = () => {
  let url = `/content/storage`;
  let params = {
    ...formBasic.value,
    ...formPart.value,
    ...formDealWith.value,
    ...formSafe.value,
    ...formDuty.value,
    ...formAudit.value,
    remark: remark.value,
    id: id.value,
    second:
      formBasic.value.second != "" && formBasic.value.second != null
        ? formBasic.value.second.split(",")
        : null,
    third:
      formBasic.value.third != "" && formBasic.value.third != null
        ? formBasic.value.third.split(",")
        : null,
  };
  axios
    .post(url, params, {
      headers: {
        "content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.data.code == 200) {
        Message.success("暂存成功");
        router.push({
          name: "FillingList",
        });
      } else {
        Message.error({
          content: res.data.message,
          background: true,
          duration: 3,
        });
      }
    });
};

//取消重置
const handleReset = () => {
  // router.push({
  //   name: "FillingList",
  // });

  let currentYear = new Date().getFullYear();
  let url = `/message/content/check?year=${currentYear}`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      if (data.isOpenFill) {
        if (data.isDeclare || data.isCompleted) {
          router.push({ name: "fillSuccess" });
        } else if (data.isHasData) {
          router.push({ name: "FillingList" });
        } else {
          router.push({ name: "movement" });
        }
      } else {
        router.push({ name: "fillSuccess" });
      }
    }
  });
};

//锚点跳转,并切换导航栏选中项
let navName = ref("basic");
const goAnchor = (e) => {
  navName.value = e;
  var id = "#" + e;
  document.querySelector(id).scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
};


let isFiexd = ref(false); //判断顶部导航位置是否要固定

//添加滚动事件
const addScrollFun = () => {
  let el = document.getElementById("wrap");
  el.addEventListener("scroll", listenScroll); // 监听页面滚动
};

const listenScroll = (e) => {
  let scrollTop0 = document.getElementById("basic").offsetTop - 200;
  let scrollTop1 = document.getElementById("part").offsetTop - 200;
  let scrollTop2 = document.getElementById("dealWith").offsetTop - 200;
  let scrollTop3 = document.getElementById("safe").offsetTop - 200;
  let scrollTop4 = document.getElementById("duty").offsetTop - 200;
  let scrollTop5 = document.getElementById("audit").offsetTop - 200;
  let scrollTop6 = document.getElementById("remark").offsetTop - 200;
  // var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  var scrollTop = document.getElementById("wrap").scrollTop;
  // console.log(scrollTop);
  if (scrollTop < scrollTop1) {
    navName.value = "basic";
  }
  if (scrollTop >= scrollTop1 && scrollTop < scrollTop2) {
    navName.value = "part";
  }
  if (scrollTop >= scrollTop2 && scrollTop < scrollTop3) {
    navName.value = "dealWith";
  }
  if (scrollTop >= scrollTop3 && scrollTop < scrollTop4) {
    navName.value = "safe";
  }
  if (scrollTop >= scrollTop4 && scrollTop < scrollTop5) {
    navName.value = "duty";
  }
  if (scrollTop >= scrollTop5 && scrollTop < scrollTop6) {
    navName.value = "audit";
  }
  if (scrollTop >= scrollTop6) {
    navName.value = "remark";
  }

  if (scrollTop > 200) {
    isFiexd.value = true;
  } else {
    isFiexd.value = false;
  }
};

let storage = ref({});
//查询暂存详情
const getDataDetails = () => {
  let url = `/content/info?dataId=${id.value}`;
  openFullScreen();
  axios.get(url).then((res) => {
    Loading.value.close();
    if (res.data.code == 200) {
      let data = res.data.data;
      for (let key in data) {
        if (typeof data[key] == "number") {
          data[key] = data[key].toString();
        }
        if (data[key] == null) {
          data[key] = '';
        }
      }

      storage.value = data;
      changeFirst(data.first);
      changeSecond(data.second);
      id.value = data.id;
      formBasic.value = {
        dataName: data.dataName,
        // standard: data.standard,
        first: data.first,
        second: Array.isArray(data.second) ? data.second.join(",") : null,
        third: Array.isArray(data.third) ? data.third.join(",") : null,
        fourth: data.fourth,
        description: data.description,
        level: data.level,
        gradeBasic: data.gradeBasic,
        dataCenter: data.dataCenter,
        coverDetail: data.coverDetail,
        source: data.source,
        dataSize: data.dataSize,
        dataCount: data.dataCount,
        cover: data.cover,
        ratio: data.ra,
        precision: data.precision,
        ratioCalculationBasis: data.ratioCalculationBasis,
      };

      formPart.value = {
        payload: data.payload,
        appName: data.appName,
        domainName: data.domainName,
        webIpAddress: data.webIpAddress,
        webIpPort: data.webIpPort,
        hostIpAddress: data.hostIpAddress,
        hostIpPort: data.hostIpPort,
        icpFilings: data.icpFilings,
        protection: data.protection,
        infrastructure: data.infrastructure,
      };

      formDealWith.value = {
        purpose: data.purpose,
        abroad: data.abroad,
        foreignDataSource: data.foreignDataSource,
        exitMode: data.exitMode,
        flows: data.flows,
        foreign: data.foreign,
        sharingMode: data.sharingMode,
        shareRange: data.shareRange,
        foreignDataSource: data.foreignDataSource,
        overseasReceiver: data.overseasReceiver,
        safetyAssessment: data.safetyAssessment,
        assessmentResult: data.assessmentResult,
      };
      formSafe.value = {
        chargeName: data.chargeName,
        chargeTitle: data.chargeTitle,
        chargeContact: data.chargeContact,
        assessment: data.assessment,
        evaluationOrg: data.evaluationOrg,
        specification: data.specification,
        evaluationDate: data.evaluationDate,
        conclusion: data.conclusion,
      };
      formDuty.value = {
        processor: data.processor,
        orgCode: data.orgCode,
        province: data.province,
        city: data.city,
      };
      //控制市区的显示
      changeProvince(data.province);
      formAudit.value = {
        reviewed: data.reviewed,
        fieldReviewed: data.fieldReviewed,
        managerReviewed: data.managerReviewed,
        informationReviewed: data.informationReviewed,
        legalReviewed: data.legalReviewed,
      };
      remark.value = data.remark;
    }
  });
};

let desc = ref("不超过50字。");
let title = ref("数据名称");
let tip = ref("填写数据的中文名称。");
let nodes = ref(null);
//输入框、下拉框聚焦
const focus = (val) => {
  switch (val) {
    case 0:
      title.value = "数据名称";
      tip.value = "填写数据的中文名称。";
      desc.value = "必填，包括数据集、数据库、数据平台等。例如：XX卫星数据库";
      break;

    case 1:
      title.value = "一级分类";
      tip.value = "根据标准规范的分类要求填写对应的数据类别。";
      desc.value = "必选";
      break;
    case 2:
      title.value = "二级分类";
      tip.value = "根据标准规范的分类要求填写对应的数据类别。";
      desc.value = "必选";
      break;
    case 3:
      title.value = "三级分类";
      tip.value = "根据标准规范的分类要求填写对应的数据类别。";
      desc.value = "必选";
      break;
    case 4:
      title.value = "四级分类";
      tip.value = "根据标准规范的分类要求填写对应的数据类别。";
      desc.value = "非必填";
      break;
    case 5:
      title.value = "数据描述";
      tip.value = "选择数据的存在形式";
      desc.value = "必填，50字以上";
      break;
    case 6:
      title.value = "数据级别";
      tip.value = "选择数据的级别。";
      desc.value = "必填，“重要”或“核心”";
      break;

    case 7:
      title.value = "分级依据";
      tip.value = "填写数据如何收集或产生。";
      desc.value = "必填，如有领域相关规范，请列出。例如：自然资源部XX规范，数据安全法";
      break;

    case 8:
      title.value = "所在数据中心";
      tip.value = "填写数据的总量，单位：GB";
      desc.value = "必填。例如：中国科学院科学数据总中心";
      break;
    case 9:
      title.value = "数据总量";
      tip.value = "写数据的总量，单位：条";
      desc.value = "必填，指数据总量数值型单位:GB";
      break;
    case 10:
      title.value = "数据记录数";
      tip.value = "选择数据对领域、群体、区域的覆盖占比";
      desc.value = '必填，单位:条，不涉及，填"无"';
      break;
    case 11:
      title.value = "数据主要来源";
      tip.value = "选择数据对领域、群体、区域的覆盖占比";
      desc.value = "必填，选择数据收集或产生的主要来源";
      break;
    case 12:
      title.value = "数据精度";
      tip.value = "填写数据对描绘对象的刻画程度。";
      desc.value = "非必填，数据对所描述对象的刻画程度。例如：精度在100米以内的机房位置坐标数据";
      break;
    case 13:
      title.value = "数据覆盖类型";
      tip.value = "填写开始数据处理活动的组织、个人名称。";
      desc.value = "必填，选择“领域”，“群体”，“区域”。领域是指数据描述的业务范畴，数据描述的是行业领域，业务条线，生产经营活动，上下游环节，内容主题等。群体是指数据描述的主体或对象集合，数据描述的是特定人群，特定团体、单位、组织，特定网络、信息系统、数据中心，特定资源、原材料、物资，特定元器件设备，特定项目，特定基础设施等。区域是指数据涉及的地区范围，数据描述的是行政区划、特定地区、物理场所、网络空间、地理环境等。";
      break;
    case 14:
      title.value = "数据覆盖具体情况";
      tip.value = "填写数据处理者者的组织机构代码或者身份证。";
      desc.value =
        "必填，数据覆盖情况指数据对特定领域、群体、区域、时段等的覆盖分布或疏密程度。根据覆盖类型，写具体情况，例如数据覆盖植物资源，包含植物物种数量10万种，例如数据覆盖材料行业，包含XX材料合成的实验数量1000个。";
      break;
    case 15:
      title.value = "数据覆盖占比";
      tip.value = "选择数据处理者所在省级行政区域";
      desc.value = "必填，对相应领域、群体、区域的覆盖占比，例如10%，格式为：xx%,数值型";
      break;
    case 16:
      title.value = "数据覆盖占比测算依据";
      tip.value = "选择数据处理者所在地级行政区域";
      desc.value =
        "必填，填写覆盖占比的测算依据，例如本数据包含10万种植物物种信息，占国内/国际植物物种的10%";
      break;
    case 17:
      title.value = "数据载体";
      tip.value = "填写数据安全负责人的姓名";
      desc.value = "必填，指数据的存在形式，数据存在所依托的媒介，包括与互联网连接的信息系统，不与互联网连接的信息系统，其他电子化数据，非电子化数据";
      break;
    case 18:
      title.value = "信息系统名称";
      tip.value = "填写数据安全负责人的职务。";
      desc.value =
        "载体类型“与互联网连接的信息系统”、“不与互联网连接的信息系统”必填，填所在信息系统名称。例如：XX卫星数据管理系统";
      break;
    case 19:
      title.value = "服务网站域名";
      tip.value = "写数据安全负责人的办公电话或者手机号码。";
      desc.value = "数据载体为“与互联网连接的信息系统”必填，服务网站的域名。例如：http://www.cnic.cas.cn/";
      break;
    case 20:
      title.value = "服务网站IP地址";
      tip.value = "填写数据处理的目的。";
      desc.value = "数据载体为“与互联网连接的信息系统”必填。例如：159.226.67.100";
      break;
    case 21:
      title.value = "服务网站IP端口";
      tip.value = "选择数据是否存在向境外传输情况。";
      desc.value = "数据载体为“与互联网连接的信息系统”必填，信息系统的端口。例如：80";
      break;
    case 22:
      title.value = "主机IP地址";
      tip.value = "选择是否开展国家网信部门组织的数据处境安全评估。";
      desc.value =
        "数据载体为“与互联网连接的信息系统”、“不与互联网连接的信息系统”必填，信息系统主机IP，如涉及多个主机，请用“,”隔开。例如：159.226.90.81";
      break;
    case 23:
      title.value = "主机端口";
      tip.value = "填写国家网信部门数据出境评估结果。";
      desc.value =
        "数据载体为“与互联网连接的信息系统”、“不与互联网连接的信息系统”必填，信息系统主机端口。例如：80";
      break;
    case 24:
      title.value = "是否进行ICP备案";
      tip.value = "选择数据是否涉及在不同法人主体之间大规模转移。";
      desc.value = "数据载体为“与互联网连接的信息系统”必填";
      break;
    case 25:
      title.value = "网络安全等级保护情况";
      tip.value = "选择是否为涉外数据。";
      desc.value =
        "数据载体为“与互联网连接的信息系统”、“不与互联网连接的信息系统”必填";
      break;
    case 26:
      title.value = "是否为关键信息基础设施";
      tip.value = "填写涉及数据存储处理的信息系统名称。";
      desc.value =
        "数据载体为“与互联网连接的信息系统”、“不与互联网连接的信息系统”必填";
      break;
    case 27:
      title.value = "数据处理目的";
      tip.value = "选择涉及数据存储处理信息系统的网络安全等级保护级别。";
      desc.value =
        "必填，填写数据处理的目的，例如XX项目科学研究，例如量子信息的科学普及";
      break;
    case 28:
      title.value = "是否涉及跨主体流动";
      tip.value = "必填，是否涉及不同法人主体间大规模转移";
      desc.value = "必填，是否涉及不同法人主体间大规模转移";
      break;
    case 29:
      title.value = "共享方式";
      tip.value = "选择是否开展数据安全风险评估。";
      desc.value = "必填，包括完全公开、有条件共享、不共享";
      break;
    case 30:
      title.value = "共享范围";
      tip.value = "填写风险评估机构的名称。";
      desc.value =
        "“完全公开”、“有条件共享”数据必填，例如课题组、所级、院内、国内、国际";
      break;
    case 31:
      title.value = "是否有涉外数据";
      tip.value = "填写风险评估所依据的规范。";
      desc.value =
        "必填，涉外数据是指涉及境外人员、组织、物品、事件、地点等数据";
      break;
    case 32:
      title.value = "涉外数据来源情况";
      tip.value = "填写风险评估时间。";
      desc.value =
        "“有涉外数据”必填，包括哪些活动，哪些国家的哪些物品、组织、地点等。例如：涉外数据来自国际合作项目XXX，包括美国50种XX类植物相关数据";
      break;
    case 33:
      title.value = "是否出境";
      tip.value = "填写风险评估的结论。";
      desc.value =
        "必填，指向境外提供境内收集和产生的数据，能被境外人员访问即为出境。";
      break;
    case 34:
      title.value = "出境方式";
      tip.value = "填写其他需要说明的情况。";
      desc.value = "“出境”数据必填，包括开放获取，签订合作协议，其他";
      break;
    case 35:
      title.value = "境外接收方";
      tip.value = "填写风险评估机构的名称。";
      desc.value =
        "“签订合作协议”出境数据必填，填写具体接收方名称,多个接收方的用“,”隔开";
      break;
    case 36:
      title.value = "是否完成数据出境安全评估";
      tip.value = "填写风险评估所依据的规范。";
      desc.value = "指按照《数据出境安全评估办法》（国家互联网信息办公室令第11号）相关要求进行数据出境安全评估。“出境”数据必填。";
      break;
    case 37:
      title.value = "数据出境安全评估结果";
      tip.value = "填写风险评估时间。";
      desc.value = "“完成数据出境安全评估”必填";
      break;

    case 38:
      title.value = "姓名";
      tip.value = "填写风险评估时间。";
      desc.value = "必填，填写组织内数据安全负责人";
      break;
    case 39:
      title.value = "职务";
      tip.value = "填写风险评估的结论。";
      desc.value = "必填";
      break;
    case 40:
      title.value = "联系方式";
      tip.value = "填写其他需要说明的情况。";
      desc.value = "必填，填写数据安全负责人手机号";
      break;
    case 41:
      title.value = "是否进行数据安全风险评估";
      tip.value = "填写其他需要说明的情况。";
      desc.value = "指数据出境安全评估之外的，依据相关规范开展的数据安全评估。必填";
      break;
    case 42:
      title.value = "数据安全风险评估机构";
      tip.value = "填写风险评估机构的名称。";
      desc.value = "“进行数据安全风险评估”必填，填写评估报告上评估机构全称";
      break;
    case 43:
      title.value = "数据安全风险评估依据规范";
      tip.value = "填写风险评估所依据的规范。";
      desc.value = "“进行数据安全风险评估”必填。例如：《XXX规范》";
      break;
    case 44:
      title.value = "数据安全风险评估时间";
      tip.value = "填写风险评估时间。";
      desc.value = "“进行数据安全风险评估”必填。例如：XXXX年XX月XX日";
      break;
    case 45:
      title.value = "数据安全风险评估结论";
      tip.value = "填写风险评估的结论。";
      desc.value = "“进行数据安全风险评估”必填";
      break;
    case 46:
      title.value = "数据处理者名称";
      tip.value = "填写其他需要说明的情况。";
      desc.value = "必填，法人主体名称";
      break;
    case 47:
      title.value = "机构代码";
      tip.value = "填写其他需要说明的情况。";
      desc.value = "必填，法人主体机构代码";
      break;
    case 48:
      title.value = "省";
      tip.value = "填写风险评估所依据的规范。";
      desc.value = "必填，法人主体所在省";
      break;
    case 49:
      title.value = "市";
      tip.value = "填写风险评估时间。";
      desc.value = "必填，法人主体所在市";
      break;
    case 50:
      title.value = "是否经过专家评审";
      tip.value = "填写风险评估的结论。";
      desc.value = "必填，是否组织相关专家对数据目录进行评审";
      break;
    case 51:
      title.value = "是否经过学科领域专家评审";
      tip.value = "填写其他需要说明的情况。";
      desc.value = "“经过专家评审”必填";
      break;
    case 52:
      title.value = "是否经过数据管理专家评审";
      tip.value = "填写其他需要说明的情况。";
      desc.value = "“经过专家评审”必填";
      break;
    case 53:
      title.value = "是否经过信息化管理专家评审";
      tip.value = "填写风险评估的结论。";
      desc.value = "“经过专家评审”必填";
      break;
    case 54:
      title.value = "是否经过法定代表人审定";
      tip.value = "填写其他需要说明的情况。";
      desc.value = "必填";
      break;
    case 55:
      title.value = "备注";
      tip.value = "填写其他需要说明的情况。";
      desc.value = "";
      break;
  }
};

const Loading = ref(null);
const openFullScreen = () => {
  Loading.value = ElLoading.service({
    lock: true,
    background: "rgba(255, 255,255, 0.5)",
  });
};

let isEdit = ref(false);
onMounted(() => {
  nextTick(() => {
    addScrollFun();
    getLevelData();
  });
  let query = route.query;
  //如果id不为空则为编辑走更新接口，反之走保存接口
  if (query.id) {
    id.value = query.id;
    isEdit.value = true;
    setTimeout(() => {
      getDataDetails();
    }, 1000);
  } else {
    isEdit.value = false;
  }
});
onBeforeUnmount(() => {
  document.getElementById("wrap").removeEventListener("scroll", listenScroll);
});
</script>

<style lang="scss" scoped>
.wrap-content {
  padding: 10px 30px;
  background: #fff;
  height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // line-height: 70px;
  .return {
    display: flex;
    align-items: center;

    .return-icon {
      width: 24px;
      height: 24px;
      border: solid 2px #111111;
      border-radius: 6px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      cursor: pointer;

      i {
        font-size: 18px;
        color: #000;
      }
    }

    span {
      font-family: PingFangSC-Medium;
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      color: #111111;
    }

    // span:last-child {
    //   color: #cccccc;
    // }
  }

  > ul {
    display: flex;

    li {
      font-size: 16px;
      margin-right: 20px;
      cursor: pointer;
      line-height: 70px;
      position: relative;

      a {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 46px;
        letter-spacing: 0px;
        color: #555555;
      }
    }

    li.active {
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 60px;
        height: 3px;
        background-color: #c22b22;
        transform: translate(-50%, -50%);
      }

      a {
        color: #c22b22;
        font-weight: bold;
      }
    }
  }
}

.fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  //  height: ;
  width: calc(100% - 200px);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
  background: #fff;
  padding: 0 30px;

  > ul {
    li.active {
      &::before {
        bottom: -2px;
      }
    }
  }
}

.title {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  // font-weight: normal;
  font-stretch: normal;
  line-height: 33px;
  color: #111111;
  font-weight: bold;

  img {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  span {
  }
}

.remark {
  label {
    font-size: 14px;
    display: block;
    min-width: 80px;
    margin-bottom: 10px;
  }
}

.footer {
  // border-top: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  margin-top: 30px;
}

.button {
  display: flex;
  align-items: center;

  > div {
    margin-left: 10px;
  }

  .btn {
    color: #fff;
    cursor: pointer;

    i {
      padding-right: 5px;
    }
  }

  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}

//标题logo
.title-logo {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.main {
  display: flex;
  position: relative;

  .main-content {
    width: calc(100% - 300px);
  }

  .point {
    width: 270px;
    min-height: 85vh;
    border-radius: 10px;
    border: solid 1px #ffe7ad;
    background-color: #fefdf6;
    // margin-left: 30px;
    margin-top: 25px;
    position: fixed;
    top: 88px;
    right: 30px;
    z-index: 10;

    .point-content {
      padding: 30px;

      h2 {
        text-align: center;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 33px;
        letter-spacing: 0px;
        color: #fd7e03;
        margin-bottom: 30px;
      }

      p {
        font-family: PingFangSC-Medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #888888;
      }

      .tip {
        font-family: PingFangSC-Medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #555555;
      }
    }
  }
}

.btn {
  padding: 0 25px;
}
</style>